import React from "react"
import {
    decimalOrIntFrom1To10NumberValidationRegex,
    domainValidationRegex,
    emailValidationRegex,
    numberValidationRegex,
    positiveNumberValidationRegex,
    phoneNumberReplacerRegex,
    phoneNumberValidationRegex,
    phoneNumberWithShortCodeValidationRegex,
    ratingOneToTenValidationRegex,
    stringValidationRegex,
    subdomainValidationRegex,
    titleValidationRegex,
    twitterHandleRegex,
    urlRegex,
    vimeoVideoIdRegex,
    youtubeVideoIdRegex,
    textHasPlaceholder,
} from "shared/imports/regex"

export function alwaysValid() {
    return true
}

export function slashValidation(str) {
    // Returns false if string is defined and contains a '/'
    // Return true in all other cases, even when string is undefined
    return !(str && str.includes("/"))
}

/**
 * Validates that string is defined and does not contain an '@'
 * @name noAtSignValidation
 * @function
 * @param {String} str - input string (may be undefined)
 * @returns {Boolean} - If string is valid or not
 */
export function noAtSignValidation(str) {
    if (!str) {
        return false
    } else {
        return !str.includes("@")
    }
}

/**
 * Validates that string is defined, contains a '.' character and does not contain a '@' character
 * @name emailDomainValidation
 * @function
 * @param {String} str - input string (may be undefined)
 * @returns {Boolean} - If string is valid or not
 */
export function emailDomainValidation(str) {
    if (!str) {
        return false
    } else {
        str = str.trim()
        return str.includes(".") && !str.includes("@") && !str.includes(" ")
    }
}

export function existsValidation(item) {
    return Boolean(item)
}

export function stringValidation(str) {
    if (str === "") return true
    return stringValidationRegex.test(str)
}

export function usernameValidation(item) {
    if (!item) {
        return false
    }
    return /^[^|\[\]]+$/gu.test(item)
}

export function titleValidation(str) {
    if (str === "") return true
    return titleValidationRegex.test(str)
}

export function requiredNumberValidation(num) {
    if (num === "") {
        return false
    }
    return numberValidationRegex.test(num)
}

export function requiredPositiveNumberValidation(num) {
    if (num === "") {
        return false
    }
    return positiveNumberValidationRegex.test(num)
}

export function decimalNumberValidation(num) {
    if (num === "") return true
    return decimalOrIntFrom1To10NumberValidationRegex.test(num)
}

export function numberValidation(num) {
    if (num === "") return true
    return numberValidationRegex.test(num)
}

export function p2aIdValidation(num, list) {
    let obj = {
        isValid: false,
        message: "Sorry, this input is invalid.",
    }

    if (num === "") {
        return obj
    }

    obj.isValid = positiveNumberValidationRegex.test(num)

    if (obj.isValid && Number(num) === 0) {
        return {
            isValid: false,
            message: "Sorry, p2a id can't be 0.",
        }
    }

    if (obj.isValid && list.includes(Number(num))) {
        obj.message = `Sorry, this p2a id: ${num} already in the list.`
        obj.isValid = false
    }

    return obj
}

export function positiveNumberValidation(num) {
    if (num === "") return true
    return positiveNumberValidationRegex.test(num)
}

export function positiveNumberValidationUpToLimit(num, limit = 140000) {
    if (num === "") {
        return true
    }
    return positiveNumberValidationRegex.test(num) && num <= limit
}

export function ratingOneToTenValidation(num) {
    if (num === "") return true
    return ratingOneToTenValidationRegex.test(num)
}

export const phoneNumberValidation = (phoneNumber, { includeShortCodes } = { includeShortCodes: false }) => {
    if (phoneNumber === "") {
        return true
    }

    // replace all (), -, and spaces with nothing in the phone number
    const cleanNumber = phoneNumber.replace(phoneNumberReplacerRegex, "")

    const regexToUse = includeShortCodes ? phoneNumberWithShortCodeValidationRegex : phoneNumberValidationRegex

    return regexToUse.test(cleanNumber)
}

export function emailValidation(email) {
    if (!email) return true
    return emailValidationRegex.test(email.trim())
}

export function urlValidation(url) {
    if (url === "") return true
    return urlRegex.test(url)
}

export function twitterHandleValidation(handle) {
    if (handle === "") return true
    return twitterHandleRegex.test(handle)
}

export function subdomainValidation(str) {
    if (str === "") {
        return true
    }
    return subdomainValidationRegex.test(str)
}

export function domainValidation(str) {
    if (str === "") {
        return true
    }
    return domainValidationRegex.test(str)
}

export function domainsValidation(domains) {
    if (domains === "") {
        return true
    }

    return domains.split(",").every((domain) => {
        return domainValidation(domain.trim())
    })
}

/**
 * This function matches the given email against a list of valid email domains
 * @name emailDomainsMatchValidation
 * @function
 * @param {string} email - The email address to be validated
 * @param {array} validDomains - An array of valid domains
 * @returns {boolean} - Whether the given email matches at least one of the valid domains
 */
export function emailDomainsMatchValidation(email, validDomains) {
    if (typeof validDomains === "object" && Array.isArray(validDomains)) {
        return validDomains.some((allowedDomain) => email && email.toUpperCase().endsWith(allowedDomain.toUpperCase()))
    } else {
        return false
    }
}

export function displayValidFeedbackIcon() {
    if (this.state.validated === false) {
        return <i className="invalid invalid-icon fa fa-times" />
    } else if (this.state.validated === true && !this.state.hideValidatedIcon) {
        return <i className="valid valid-icon fa fa-check" />
    }
    return null
}

export function displayValidFeedbackMessage(message) {
    if (this.state.validated === false) {
        return <span className="invalid invalid-message">{message}</span>
    } else if (this.state.validated === true) {
        return null
    }
}

export function genValidationFunction(validation_function) {
    return function (e) {
        let valid = validation_function(e.target.value)

        // if it's a required field, then return false if it's empty
        // @will -- for some reason this breaks things I'm not sure why!
        // if (this.props.required)
        //     valid = valid & e.target.value != ''

        this.setState({ validated: valid }, () => this.state.validated)

        return valid
    }
}

export function validateCustomFieldTypes(value, fieldType) {
    switch (fieldType) {
        case DjangIO.app.person.types.TagType.string.value:
            return Boolean(value)
        case DjangIO.app.person.types.TagType.number.value:
            if (Number(value) === 0) return true
            return Boolean(Number(value))
        case DjangIO.app.person.types.TagType.single_option_list.value:
            if (typeof value == "object") return value.every((subvalue) => Boolean(subvalue))
            return Boolean(value)
        case DjangIO.app.person.types.TagType.boolean.value:
            if (value === "true" || value === "false") return true
            return false
        default:
            return true
    }
}

// Let's phase this component out in favor of AddressAutocompleteComponent.
// load the google autocomplete dropdown object for a given input field
// https://developers.google.com/maps/documentation/javascript/places-autocomplete
function loadGoogleAutoComplete(input, updatingFn, dbName) {
    const params = {
        types: ["address"],
        // pay attention to what fields you are requesting
        // otherwise we may get charged for geocoding.
        // For now, all we need is the formatted_address
        fields: ["formatted_address"],
    }

    const googleTypeahead = new google.maps.places.Autocomplete(input, params)

    googleTypeahead.addListener("place_changed", () => {
        const place = googleTypeahead.getPlace().formatted_address
        updatingFn({ [dbName]: place })
    })
}

export function addressInitializingFn(input, updatingFn, dbName) {
    if (input) {
        if (document.readyState === "complete") {
            loadGoogleAutoComplete(input, updatingFn, dbName)
        } else {
            window.onload = () => {
                loadGoogleAutoComplete(input, updatingFn, dbName)
            }
        }
    }
}

export function vimeoLinkValidation(str) {
    return vimeoVideoIdRegex.test(str)
}

export function youtubeLinkValidation(str) {
    return youtubeVideoIdRegex.test(str)
}

export function vimeoOrYoutubeValidation(str, validateExists = true) {
    if (!str) {
        // If no link is provided, return false if should validate that the string is defined
        // Otherwise, if shouldn't validate that the string is defined, return true
        return !validateExists
    }

    return vimeoLinkValidation(str) || youtubeLinkValidation(str)
}

export function textHasPlaceholderValidation(str) {
    return textHasPlaceholder.test(str)
}
